.ng-select {
  &.ng-arrow-black .ng-select-container span.ng-arrow-wrapper {
    &:after,
    &:before {
      color: black;
    }
  }
  & .ng-select-container {
    height: 48px !important;
    border: 1px solid #dee2e6;
    border-radius: unset;
    & .ng-arrow-wrapper {
      height: 50%;
      padding-right: 20px;
      & .ng-arrow {
        display: none;
      }
    }
    & span.ng-arrow-wrapper:before {
      content: '\f077';
    }
    & span.ng-arrow-wrapper:after {
      content: '\f078';
      visibility: hidden;
    }
    & span.ng-arrow-wrapper:before,
    span.ng-arrow-wrapper:after {
      position: absolute;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      color: #979797;
      font-size: inherit;
      text-rendering: auto;
    }
  }
  &.ng-select-focused {
    &:not(.ng-select-opened) > .ng-select-container {
      border: solid 0.5px black;
      outline: solid 1px black;
      box-shadow: unset;
    }
    & span.ng-arrow-wrapper:before,
    span.ng-arrow-wrapper:after {
      color: black;
    }
  }
  & .ng-dropdown-panel {
    &.ng-select-bottom {
      border-radius: unset;
    }
    & .ng-dropdown-panel-items .ng-option {
      &.ng-option-marked {
        background-color: #d4d8dc;
        color: black;
        &.ng-option-selected {
          background-color: #3b5877;
          color: #ffffff;
        }
      }
      &.ng-option-selected {
        background-color: #3b5877;
        color: #ffffff;
      }
    }
  }
  &:not(.ng-select-opened) {
    & span.ng-arrow-wrapper:before {
      visibility: hidden !important;
    }
    & span.ng-arrow-wrapper:after {
      visibility: visible !important;
    }
  }
}

.ng-select.no-border {
  & .ng-select-container {
    border: unset;
    &:hover {
      box-shadow: unset;
    }
  }

  &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
    border: unset;
    outline: unset;
  }

  & .ng-dropdown-panel {
    border: none;
    box-shadow: 0 3px 4px -3px rgba(0, 0, 0, 0.24);
    & .ng-dropdown-panel-items .ng-option {
      text-transform: capitalize;
      color: #000000d9;
      &.ng-option-marked,
      &.ng-option-selected {
        background-color: white;
        color: #8a8a8ad9;
        &.ng-option-label {
          font-weight: 500;
        }
      }
    }
    &.ng-select-bottom {
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }
}

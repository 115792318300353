.timeline__container {
  position: relative;

  &::before {
    // this is the timeline vertical line
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    height: 98%;
    width: 0.063rem;
    border: solid 1px #85878b;
  }
}

.timeline__block {
  display: flex;
  align-items: flex-start;
  margin-bottom: 3.125rem;
}

.timeline__block__last {
  position: relative;

  &::before {
    // this is the timeline vertical line
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    height: 98%;
    width: 0.063rem;
    border: solid 5px #ffffff;
  }
}

.timeline__text {
  margin-left: 4.25rem;
  margin-top: -0.2rem;
}

.timeline__oval-current {
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 100%;
  background-color: #a90a0a;
  z-index: 1;
}

.timeline__oval-next {
  width: 1.375rem;
  height: 1.375rem;
  margin-right: 0.435rem;
  margin-left: 8px;
  border-radius: 100%;
  border: solid 1px #979797;
  background-color: #85878b;
  z-index: 1;
}

.timeline__oval-past {
  width: 1.375rem;
  height: 1.375rem;
  margin-right: 0.435rem;
  margin-left: 8px;
  border-radius: 100%;
  border: solid 1px #000000;
  background-color: #000000;
  z-index: 1;
}

@import 'mixins.scss';

.successSubscribePopUp .modal-dialog {
  max-width: 320px;
  @include max-width-popup(400px, 991px);
  @include max-width-popup(480px, 1200px);
}

.modalTemasLegalesPopUp .modal-dialog {
  max-width: 320px;
  @include max-width-popup(700px, 991px);
  @include max-width-popup(900px, 1200px);
}

.guiaTallaPopup .modal-dialog {
  padding-top: 0px;
  transform: none !important;
  margin: 0 !important;
}

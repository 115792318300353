/* Fuentes */
@import 'mixins.scss';
@import '../assets/scss/paper-kit/variables';

h1,
.header-1 {
  @include font-style(2.25rem, black, normal, $font-family-primary);
  @include font-mobile-style(1.875rem, normal);
  font-weight: normal;
}

h2,
.header-2 {
  @include font-style(1.75rem, black, normal, $font-family-primary);
  @include font-mobile-style(1.5rem, normal);
  font-weight: normal;
  line-height: normal !important;
}

h3,
.header-3 {
  @include font-style(1.375rem, black, normal, $font-family-primary);
  @include font-mobile-style(1.1875rem, normal);
  font-weight: normal;
}

h4,
.header-4 {
  @include font-style(0.8125rem, black, normal, $font-family-primary);
  @include font-mobile-style(0.8125rem, normal);
  font-weight: normal;
}

.subtitle-1 {
  @include font-style(1.25rem, #656565, normal, $font-family-secondary);
  @include font-mobile-style(1.125rem, normal);
  font-weight: bold;
}

.subtitle-2 {
  @include font-style(1rem, black, 0.55px, $font-family-secondary);
  @include font-mobile-style(0.9375rem, normal);
  font-weight: normal;
}

.subtitle-3 {
  @include font-style(1.188rem, black, normal, $font-family-secondary);
  @include font-mobile-style(1.063rem, normal, 1.5rem);
  font-weight: normal;
  line-height: 1.8rem;
}

.subtitle-4 {
  @include font-style(1rem, black, normal, $font-family-secondary);
  @include font-mobile-style(0.938rem, normal);
  font-weight: bold;
}

p,
.paragraph-1 {
  @include font-style(0.875rem, #656565, normal, $font-family-secondary);
  @include font-mobile-style(0.8125rem, normal);
  font-weight: normal;
}

.paragraph-2 {
  @include font-style(0.75rem, #656565, normal, $font-family-secondary);
  @include font-mobile-style(0.75rem, normal);
  font-weight: normal;
}

.paragraph-3 {
  @include font-style(0.625rem, #656565, initial, $font-family-secondary);
  @include font-mobile-style(0.625rem, normal);
  font-weight: normal;
}

.paragraph-4 {
  @include font-style(0.75rem, black, normal, $font-family-secondary);
  @include font-mobile-style(0.6875rem, normal);
  font-weight: bold;
}

button,
.btn {
  &:not(.fa, .nc-icon) {
    @include font-style-btn(0.813rem, normal, $font-family-secondary);
    font-weight: bold;
  }
}

@import '../assets/scss/paper-kit/variables';

.navbar {
  box-shadow: none;
  border-top: 10px solid var(--border-color) !important;
  @media screen and (min-width: 992px) {
    border-bottom: 1px solid var(--border-color) !important;
  }
}

.subnav-link {
  font-size: 0.75rem;
  color: #000;
  letter-spacing: normal;
  font-family: Be Vietnam Pro, Helvetica, Arial, sans-serif;
  font-weight: 700;
}

.navbar-nav {
  .nav-color {
    color: #000000;
  }

  .nav-item .btn.dropdown-toggle {
    background-color: transparent;
  }

  .nav-item .btn i {
    color: #000000;
  }
  a.btn.disabled {
    background-color: transparent;
  }
  .navBarFest {
    font-size: 0.9rem;
    margin: 0px;
    padding: 25px 15px;
    @media screen and (max-width: 1000px) {
      font-size: 1.1rem;
    }
  }
  .dropdown {
    .dropdown-content,
    .dropdown-content-temp {
      visibility: hidden;
      opacity: 0;
      transition: 0s;
      position: fixed;
      width: 100%;
      left: 0;
      z-index: 100;
      background-color: #ffffff;
      padding-bottom: 20px;
      -webkit-box-shadow: 0 26px 34px -2px rgba(0, 0, 0, 0.12);
      -moz-box-shadow: 0 26px 34px -2px rgba(0, 0, 0, 0.12);
      box-shadow: 0 26px 34px -2px rgba(0, 0, 0, 0.12);
    }
    &:not(.no-hover):hover {
      .dropdown-content {
        opacity: 1;
        visibility: visible;
        transition-delay: 0.4s;
      }
      .nav-link,
      .nav-link a {
        &::before {
          opacity: 0;
          @media screen and (min-width: 992px) {
            opacity: 1;
          }
          z-index: -1;
        }
        font-weight: bold;
        cursor: pointer;
      }
    }
    &:active {
      .nav-link {
        font-weight: bold;
      }
    }
    .dropdown-item:hover {
      background-color: #ffffff;
    }
  }
}

.navbar .navbar-nav .nav-item .nav-link {
  font-weight: normal;
  border-color: black;
  color: black;
  @media screen and (max-width: 991px) {
    font-size: 1.3rem;
    margin-left: 7% !important;
    border-bottom: 1px solid #fff;
    width: 86%;
    padding-left: 0;
    &[aria-expanded='true'] {
      padding-right: 11vw;
      width: 100%;
      margin-left: 0 !important;
      padding-left: 7% !important;
      background: white;
      color: black !important;
    }
  }
}

.nav-menu {
  @media screen and (max-width: 991px) {
    width: initial;
    justify-content: unset;
  }
}

.nav-notification-bubble {
  position: absolute;
  right: 0px;
  top: 50%;
  padding: 0.4em 0.6em;
  cursor: pointer;
  @media screen and (max-width: 991px) {
    right: 10px;
  }
}

.navbar-dropdown {
  .dropdown-content {
    visibility: hidden;
    opacity: 0;
    transition: 0s;
    position: fixed;
    width: 100%;
    left: 0;
    z-index: 100;
    background-color: #ffffff;
    padding-bottom: 20px;
    -webkit-box-shadow: 0 26px 34px -2px rgba(0, 0, 0, 0.12);
    -moz-box-shadow: 0 26px 34px -2px rgba(0, 0, 0, 0.12);
    box-shadow: 0 26px 34px -2px rgba(0, 0, 0, 0.12);
  }
  &:not(.no-hover):hover {
    .dropdown-content {
      opacity: 1;
      visibility: visible;
      transition-delay: 0.4s;
    }
    .nav-link,
    .nav-link a {
      letter-spacing: normal;
      font-weight: 900;
      cursor: pointer;
      &::before {
        opacity: 0;
        z-index: -1;
        @media screen and (min-width: 992px) {
          opacity: 1;
        }
      }
    }
    .nav-link {
      a {
        padding-bottom: 3px;
        border-bottom: 2px solid var(--dark-red-color);
      }
    }
  }
  &:active {
    .nav-link {
      font-weight: bold;
    }
  }
  .dropdown-item:hover {
    background-color: #ffffff;
  }
  @media screen and (max-width: 991px) {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 100% !important;
  }
}

.nav-bar-menu {
  margin: 0px !important;
  padding: 10px 1.075rem !important;
  @media screen and (max-width: 991px) {
    padding: 15px 8% !important;
    width: 100%;
    font-weight: normal !important;
    font-size: 1.125rem !important;
    letter-spacing: 1.02px !important;
  }
}

.navbar-title {
  font-size: 0.9rem;
  color: #000;
  letter-spacing: normal;
  font-family: Red Rose, Helvetica, Arial, sans-serif;
  font-weight: 400;
  @media screen and (max-width: 991px) {
    font-weight: normal;
    font-size: 1.125rem;
    letter-spacing: 1.02px;
  }
}

.navbar-subtitle {
  font-size: 0.813rem;
  color: black;
  letter-spacing: 0.51px;
  font-weight: bold;
  text-align: left;
  @media screen and (min-width: 991px) {
    text-transform: uppercase;
  }
}

.navbar-item {
  color: black;
  font-size: 0.7rem;
  letter-spacing: 0.51px;
  text-transform: capitalize;
  font-weight: bold;
  @media screen and (min-width: 991px) {
    letter-spacing: 0.2px;
    font-size: 0.9rem;
    font-weight: 300;
  }
}

.menu-link {
  text-decoration: underline !important;
  font-size: 0.9rem;
}

.unique-sub-menu {
  margin-right: 0;
  margin-left: 0;
  @media screen and (min-width: 992px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.ver-todo-image {
  font-size: 0.9rem;
  color: black;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  text-decoration: underline !important;
}

.mobile-navbar-parent {
  @extend .subtitle-3;
  color: #000 !important;
  font-weight: 700 !important;
}

.mobile-navbar-item {
  @extend .paragraph-1;
}

.mobile-navbar-ver-todo {
  @extend .paragraph-2;
  color: #a8afb9 !important;
  text-decoration: underline !important;
}

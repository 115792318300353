@import '../assets/scss/paper-kit/variables';
@import './styleguide';

@media screen and (min-width: 600px) {
  .jumbotron-manuals {
    position: relative;
    background: no-repeat center center;
    width: 100%;
    padding: 16rem 2rem 2rem 2rem;
    background-size: 100% 100%;
  }
}

@media screen and (max-width: 600px) {
  .jumbotron-manuals {
    text-align: center !important;
  }
}
.jumbotron-manuals {
  border-radius: unset;
}

.download-manual-jumbotron {
  background-image: url($urlPath + ('/img/sections/manuales.jpg'));
  background-size: auto;
  @media screen and (max-width: 768px) {
    background-image: url($urlPath + ('/img/sections/manuales-movil.jpg'));
  }
}

.jumbotron-devoluciones {
  background-image: url($urlPath + ('/img/sections/devoluciones.jpg'));
  @media screen and (max-width: 768px) {
    background-image: url($urlPath + ('/img/sections/devoluciones_movil.jpg'));
  }
}

@mixin input-size($padding-vertical, $padding-horizontal, $height) {
  padding: $padding-vertical $padding-horizontal;
  height: $height;
}

@mixin light-form() {
  border-radius: 0;
  border: 0;
  padding: 0;
  background-color: transparent;
}

@mixin placeholder() {
  @include optional-at-root('::-moz-placeholder') {
    @content;
  }
  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }
}

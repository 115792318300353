@import 'node_modules/swiper/swiper-bundle.min.css';

.swiper-pagination {
  bottom: 0px !important;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  padding: 0.25rem;
  margin: 1px 0.5rem;

}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
  background: black;
  border: 2px solid white;
  border-radius: 50%;
  box-shadow: 0 0 0 1px black;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev, .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  transform:scale(0.6666666666666667) !important;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev, .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  transform:scale(0.4166666666666667) !important;
}

.swiper-pagination-bullet {
  width: 0.75rem;
  height: 0.75rem;
}

:root {
  --swiper-theme-color: #000000;
}

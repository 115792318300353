ngx-gallery{
    width: 100% !important;
    height: 100vh !important;
    .ngx-gallery-arrow-right{
        right: 20px !important;
    }
    .ngx-gallery-preview-img-wrapper{
        background: rgba(232, 231, 227, 0.96) !important;
    }
}

@import 'tailwind-styles.scss';
/* You can add global styles to this file, and also import other style files */
@import 'node_modules/@ng-select/ng-select/themes/default.theme.css';
@import 'styles/vendors/swiper';
@import 'styles/vendors/cookiebot';
@import 'node_modules/ngx-spinner/animations/timer.css';

@import 'assets/scss/paper-kit/variables';

@import 'styles/mixins.scss';
@import 'styles/styleguide';
@import 'styles/select.scss';
@import 'styles/popup.scss';
@import 'styles/utils.scss';
@import 'styles/quill.core.css';
@import 'styles/jumbotron.scss';
@import 'styles/timeline.scss';
@import 'styles/navbar.scss';

/* Se modifica el tamaño máximo de los popover */
$popover-max-width: 580px;

.popover-body {
  overflow-y: auto;
  white-space: pre-wrap;
  width: 425px;
  max-height: 90vh;
  height: auto;
  overflow-x: hidden;
  button.close {
    font-size: 0px;
    line-height: 0;
    opacity: 1;
    margin-top: 8px;
    span {
      color: transparent;
      background-image: url($urlPath + ('/img/cart/cerrar.png'));
      background-repeat: no-repeat;
      background-position: 0 0;
      display: block;
      height: 15px;
      width: 15px;
    }
  }
}
/* Ajusto posición de cesta según resolución */

@media screen and (max-width: 1800px) {
  .popover-body {
    position: fixed;
    right: -200px;
  }
}
@media screen and (max-width: 1700px) {
  .popover-body {
    right: -150px;
  }
}
@media screen and (max-width: 1600px) {
  .popover-body {
    right: -100px;
  }
}
@media screen and (max-width: 1500px) {
  .popover-body {
    right: -50px;
  }
}

@media screen and (max-width: 600px) {
  .popover-body {
    right: auto;
    position: absolute;
    left: auto;
    width: 103vw;
    height: 100vh;
    max-height: 100vh;
  }
  .prodCartColumn {
    max-width: 68%;
  }
}

p.obligatoryField {
  font-size: 12px;
  color: orangered;
}

.page-header.page-header-xs {
  min-height: 25vh !important;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0px 15px;
  }
}

@media screen and (max-width: 991px) {
  #bloqueJoyas {
    margin-top: -42px !important;
    margin-bottom: -17px;
  }
  .verRelojesMobile.show {
    margin-bottom: 75px !important;
  }
  .tiposMobile {
    margin-top: 10px;
  }
  .relojesHombreMobile {
    margin-top: -42px;
  }
  .joyasInfantilMobile {
    margin-top: -37px !important;
  }
  /* ESTO ES PARA QUE HAGA EFECTO ACORDEÓN CON TRANSICIÓN */
  /*.dropdown .dropdown-menu {
-webkit-transition: max-height 1s, opacity 0.2s 0.1s, visibility 0s 0.3s!important;
-moz-transition: max-height 1s, opacity 0.2s 0.1s, visibility 0s 0.3s!important;
-ms-transition: max-height 1s, opacity 0.2s 0.1s, visibility 0s 0.3s!important;
-o-transition: max-height 1s, opacity 0.2s 0.1s, visibility 0s 0.3s!important;
transition: max-height 1s, opacity 0.2s 0.1s, visibility 0s 0.3s!important;

max-height: 0!important;
display: block!important;
overflow: hidden!important;
opacity: 0!important;
visibility: hidden!important;
}

.dropdown.show .dropdown-menu {
-webkit-transition: max-height 1s, opacity 0.2s, visibility 0s!important;
-moz-transition: max-height 1s, opacity 0.2s, visibility 0s!important;
-ms-transition: max-height 1s, opacity 0.2s, visibility 0s!important;
-o-transition: max-height 1s, opacity 0.2s, visibility 0s!important;
transition: max-height 1s, opacity 0.2s, visibility 0s!important;

max-height: 100%!important;
opacity: 1!important;
visibility: visible!important;
}*/

  .dropdown.show .dropdown-menu {
    /*-webkit-transform: translate3d(0px, 0px, 0px);
transform: translate3d(0px, 0px, 0px);*/
    visibility: visible !important;
    background-color: #ffffff !important;
    &#navbarDropdownContentMujer.show,
    &#navbarDropdownContentHombre.show,
    &#navbarDropdownContentInfantil.show {
      padding-left: 30px;
      padding-right: 21px;
    }
  }
  .border-bottom-menu {
    border-bottom: solid 0.3px rgba(0, 0, 0, 0.1);
  }
  .nav-item.subItem .nav-link.linkMiCuenta {
    font-size: 1rem !important;
    letter-spacing: 2.08px;
    img {
      max-width: 57px;
      margin-right: 7px;
    }
  }
  #navbarDropdownMarcas[aria-expanded='true'],
  #navbarDropdownHombre[aria-expanded='true'],
  #navbarDropdownMujer[aria-expanded='true'],
  #navbarDropdownInfantil[aria-expanded='true'] {
    background: white;
    color: black !important;
    font-weight: bold;
    position: relative;
    .nc-icon {
      position: relative;
      right: 12px;
    }
  }
  #navbarDropdownMarcas[aria-expanded='true'] {
    padding: 21px 15px;
    top: 7px;
  }
  #navbarDropdownMujer[aria-expanded='true'],
  #navbarDropdownHombre[aria-expanded='true'],
  #navbarDropdownInfantil[aria-expanded='true'] {
    padding: 26px 21px 0px 15px;
    top: -1px;
    margin-top: -1px;
    margin-bottom: -3px;
    font-size: 1.3rem;
  }
  .navbar .navbar-nav #navbarDropdownContentMarcas.dropdown-menu,
  .navbar .navbar-nav #navbarDropdownContentMujer.dropdown-menu,
  .navbar .navbar-nav #navbarDropdownContentHombre.dropdown-menu,
  .navbar .navbar-nav #navbarDropdownContentInfantil.dropdown-menu {
    max-height: 100%;
    display: none;
    &.show {
      display: block;
    }
  }

  ul.navbar-nav .close {
    font-size: 1.6rem;
    font-weight: 100;
    margin-right: 5px;
    position: relative;
    top: -1px;
    color: #000;
    text-shadow: none;
  }
  .hellobar-container button.close {
    top: 0% !important;
  }
}

.gm-style-iw-d {
  color: #66615b;
  font-size: 14px;
  font-weight: 300;
  font-family: 'Montserrat', 'Helvetica', Arial, sans-serif;
}

.cursor-pointer {
  cursor: pointer;
}

.wrapper {
  height: 100%;
}

.cc-banner .cc-message {
  font-size: 0.7rem;
  text-align: center;
}

.fav-icon i {
  font-size: 26px;
  color: #fff;
  text-shadow:
    -1px 0 #000,
    0 1px #000,
    1px 0 #000,
    0 -1px #000;
}

.borde-gris {
  border: solid 2px #bfbfbf !important;
}

.color-white {
  color: #fff !important;
}

.dropdown .dropdown-menu {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;

  max-height: 0;
  display: block;
  overflow: hidden;
  opacity: 0;
}

.dropdown.show .dropdown-menu {
  /* For Bootstrap 4, use .dropdown.show instead of .dropdown.open */
  max-height: 100vh;
  opacity: 1;
}

@media screen and (max-width: 768px) {
  html,
  body {
    overflow-x: clip;
  }
  html {
    &.nav-open,
    &.filtro-open,
    &.sidebar-open {
      overflow-y: hidden;
    }
  }
  #navbarDropdownContentMarcas > li {
    padding: 0px !important;
  }
}

.navbar .navbar-nav .nav-item .nav-link {
  font-weight: bold;
  border-color: black;
  color: black;
  cursor: pointer;
}

.btn {
  line-height: 2;
}
input.form-control {
  height: 48px !important;
  border: 1px solid #dee2e6;
  font-size: 0.75rem !important;
}

.carousel-control-prev,
.carousel-control-next {
  background-color: rgb(78, 78, 78, 0.3);
  &:hover {
    background-color: rgb(78, 78, 78);
  }
}

.carousel-indicators .active {
  background-color: #4e4e4e !important;
}

input {
  font-size: 16px !important;
}

@media screen and (max-width: 768px) {
  .nav-icon {
    width: 22px;
  }
}

@media screen and (min-width: 769px) {
  .nav-icon {
    width: 32px;
  }

  .h-md-100 {
    height: 100% !important;
  }
}

.border-double-grey {
  border-bottom: 2px solid #e3e3e3 !important;
}

.borde-red {
  border: solid 2px #e51414 !important;
}

.border-top-double-grey {
  border-top: 2px solid #e3e3e3 !important;
}

.block-section {
  padding: 50px 0px 0px 0px;
  @media screen and (max-width: 768px) {
    padding: 40px 0px 0px 0px;
  }
}

.mini-block-section {
  padding: 20px 0 !important;
}

.section {
  min-height: 36vh;
}

.nav-icono {
  padding-left: 0 !important;
  padding-right: 0 !important;
  outline: 0;
}

/* Colors */
.text-black {
  color: #000 !important;
}

.color_dark_grey {
  color: #6d7278 !important;
}

.color_grey {
  color: #a3a3a3 !important;
}

/*  Backgrounds */
.bg-black {
  background-color: #000 !important;
}

.bg-title-caja-pago {
  background-color: var(--card-basket-color);
  max-height: 36px;
}

.border-gray-pago {
  border: 1px solid var(--card-basket-color);
}

/* Max height */
.max-height-25 {
  max-height: 25px !important;
}

.max-height-13 {
  max-height: 13px !important;
}
.max-height-15 {
  max-height: 15px !important;
}

.link-legal {
  text-decoration: underline;
}

.btn-color {
  border: none;
  &.btn-border-color {
    border: 0.2px solid #979797;
  }
}

.section.main-page {
  padding-top: 70px;
}

.jumbotron {
  position: relative;
  min-height: 35vh;
  background-size: cover;
  background-position: center;
  border-radius: unset;

  @media screen and (max-width: 768px) {
    min-height: 25vh;
  }

  &.jumbotron-full {
    background-repeat: no-repeat;
    @media screen and (min-width: 600px) {
      min-height: 65vh;
    }
    @media screen and (min-width: 991px) {
      min-height: 34vh;
    }
    &.aboutUs {
      background-position: bottom;
    }
  }

  .jumbotron-text {
    position: absolute;
    text-align: center !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media screen and (min-width: 600px) {
      top: 60%;
      transform: translateX(-50%);
    }
  }
}

span.checkboxRed:before {
  outline: 1px solid red !important;
}

span.checkboxBlack:before {
  outline: 1px solid black !important;
}

/* ESTO VA AQUÍ PORQUE EN EL CSS DE LA PÁGINA NO SE SOBREESCRIBE */
@media screen and (max-width: 1320px) {
  #bloqueMarca2 {
    .block-container {
      top: -10%;
    }
    .btn-outline-info {
      margin-top: 7%;
    }
  }
  #bloqueMarca3 {
    .block-container {
      top: -7%;
    }
    .btn-outline-info {
      margin-top: 10%;
    }
  }
  #bloqueMarca4 {
    .block-container {
      top: -6%;
    }
    .btn-outline-info {
      margin-top: 7%;
    }
  }
  #bloqueMarca5 {
    .block-container {
      top: -5%;
    }
    .btn-outline-info {
      margin-top: 7%;
    }
  }
  #bloqueMarca6 {
    .block-container {
      top: -7%;
    }
    .btn-outline-info {
      margin-top: 7%;
    }
  }
  #bloqueMarca7 {
    .block-container {
      top: -5%;
    }
    .btn-outline-info {
      margin-top: 7%;
    }
  }
  #bloqueMarca8 {
    .block-container {
      top: -7%;
    }
    .text-center img {
      width: 30%;
    }
    div.white-color {
      top: 10%;
      position: relative;
    }
    .btn-outline-neutral {
      margin-top: 11%;
    }
  }
  #bloqueMarca9 {
    .block-container {
      top: -10%;
    }
    .btn-outline-info {
      margin-top: 4%;
    }
  }
}

@media screen and (max-width: 991px) {
  #loginCesta div.col-6.d-md-none.text-center.pb-5 {
    display: none !important;
  }
  #loginCesta form.login-form {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  #loginCesta #espacioLogin {
    width: 100%;
  }

  #loginCesta .container > div {
    height: 100%;
  }
  #loginCesta .container,
  #loginCesta .container > div > div {
    height: 90%;
  }
}

.logoLandingMarca img {
  max-width: 160px;
}

.icon-favorite {
  width: 35px;
  height: 35px;
  background-size: 100% !important;
  display: block;
  background: url($urlPath + ('/img/favoritos/false.svg'));
  &.active {
    background: url($urlPath + ('/img/favoritos/true.svg'));
  }
}

.subscribe-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: 1rem;
  margin-left: 1rem;
  @media screen and (min-width: 1200px) {
    max-width: 1140px;
    width: 50%;
    margin-right: auto;
    margin-left: auto;
  }
}

.swal2-container {
  z-index: 10000000 !important;
}

/* SOBRESCRIBO TAMAÑO Y COLOR DE ICONOS EN FGP-VENTAJAS-SECTION */
.ventaja__icon .icon {
  width: 50px !important;
  height: 50px !important;
  background-color: #baab9e !important;
}

@media screen and (min-width: 991px) and (max-width: 1500px) {
  .navbar-nav > * > li {
    display: inline-block;
  }
  .nav-menu {
    /* display: inline-block; */
    width: 85%;
  }
  .navbar-right {
    display: inline-block;
  }
}

@media screen and (min-width: 576px) {
  .carousel-inner {
    padding-top: 37%;
  }
  .carousel-item {
    position: absolute;
    top: 0;
  }
}

.hide-nav {
  transform: translateY(-100%);
}

@layer base {
  ul,
  ol {
    list-style: disc;
    padding-inline-start: 2.5rem;
  }
}

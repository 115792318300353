@mixin font-style($font-size, $font-color, $letter-spacing, $family, $line-height: inherit) {
  font-size: $font-size;
  color: $font-color;
  letter-spacing: $letter-spacing;
  font-family: $family;
}

@mixin font-style-btn($font-size, $letter-spacing, $family) {
  font-size: $font-size;
  letter-spacing: $letter-spacing;
  font-family: $family;
}

@mixin font-tablet-style($font-size, $letter-spacing) {
  @media screen and (max-width: 768px) {
    font-size: $font-size;
    letter-spacing: $letter-spacing;
  }
}

@mixin font-mobile-style($font-size, $letter-spacing, $line-height: inherit) {
  @media screen and (max-width: 991px) {
    font-size: $font-size;
    letter-spacing: $letter-spacing;
    line-height: $line-height;
  }
}

@mixin max-width-popup($max-width, $screen) {
  @media screen and (min-width: $screen) {
    max-width: $max-width;
  }
}

@use '../assets/scss/paper-kit/variables';

.bold-text {
  font-weight: 700 !important;
}

.thin-text {
  font-weight: 100 !important;
}

.light-text {
  font-weight: 300 !important;
}

.primary-color {
  color: $primary-color !important;
}

.secondary-color {
  color: $success-color !important;
}

.error-color {
  color: $warning-color !important;
}

.grayish-orange-color {
  color: $grayish-orange-color !important;
}

.dark-red-color {
  color: $dark-red-color !important;
}

.black-color {
  color: $black-color !important;
}

.gris-10-color {
  color: var(--gris10) !important;
}

.bg-gris-10-color {
  background-color: var(--gris10) !important;
}

.pre-sale-primary-color {
  color: var(--pre-sale-primary-color) !important;
}
.pre-sale-secondary-color {
  color: var(--pre-sale-secondary-color) !important;
}

.bg-pre-sale-primary {
  background-color: var(--pre-sale-primary-color) !important;
}
.bg-pre-sale-secondary {
  background-color: var(--pre-sale-secondary-color) !important;
}

.dark-gray-color {
  color: $dark-gray-color !important;
}

.white-color {
  color: $white-color !important;
}

.light-grayish-orange-color {
  color: $light-grayish-orange-color !important;
}

.bg-light-grayish-orange-color {
  background-color: $light-grayish-orange-color !important;
}

.muted-color {
  color: $muted-color !important;
}

.gris-9-color {
  color: $gris9-color !important;
}

.gris-3-color {
  color: $gris3-color !important;
}

.form-control.no-border {
  border: none !important;
  outline: none !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-letter-normal {
  letter-spacing: normal;
}

.embed-responsive.embed-responsive-80::before {
  padding-top: 80%;
}

/* BACKGROUND */
.bg-success {
  background-color: $success-color !important;
}

.bg-white {
  background-color: $white-color !important;
}

.bg-gris3 {
  background-color: $gris3-color !important;
}

.bg-gris6 {
  background-color: $gris6-color !important;
}

.bg-gris7 {
  background-color: $gris7-color !important;
}

.bg-gris11 {
  background-color: $gris11-color !important;
}

/* TEXT DECORATION */
.text-underline {
  text-decoration: underline !important;
}

.btn-space-1 {
  line-height: 1 !important;
}

video {
  position: relative;
}

.background-ventajas {
  background-color: var(--card-background-color);
}

.rotate-180 {
  transform: rotate(180deg);
}

ngb-accordion {
  .card {
    margin-bottom: 0 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    .card-header {
      padding: 0 !important;
      background-color: $white-color !important;
      &:first-child {
        border-radius: 0 !important;
      }
      a {
        &:hover,
        &:focus {
          color: $danger-color;
        }
        color: #000000 !important;
      }
      button {
        text-align: inherit;
        text-transform: none;
        width: 100%;
      }
    }
    .collapse {
      display: none;
      position: relative;
      overflow: hidden;
      &.show {
        display: block;
      }
      font-size: 10px;
    }
  }
  .card:not(.card-plain):hover {
    transform: none !important;
  }
}

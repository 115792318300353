@tailwind base;
@tailwind components;
@tailwind utilities;

/* Aplicar scrollbar global */
@layer base {
  * {
    @apply fgs-scrollbar-thin fgs-scrollbar-track-rounded-full fgs-scrollbar-thumb-rounded-full fgs-scrollbar-thumb-primary-800 fgs-scrollbar-track-primary-200;
  }
}

@import 'styles/fonts';

$pswp__show-hide-transition-duration: 333ms !default;
$pswp__controls-transition-duration: 333ms !default;
$pswp__background-color: #000 !default;
$pswp__placeholder-color: #222 !default;
$pswp__box-sizing-border-box: true !default;
$pswp__root-z-index: 1500 !default;
$pswp__assets-path: 'assets/img/photo_swipe/' !default;
$pswp__error-text-color: #ccc !default;
$pswp__include-minimal-style: true !default;

$background-color: rgba(232, 231, 227, 0.96);

@font-face {
  font-family: 'Be Vietnam Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../assets/fonts/google/BeVietnamPro-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Be Vietnam Pro';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../assets/fonts/google/BeVietnamPro-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Be Vietnam Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../assets/fonts/google/BeVietnamPro-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Red Rose';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../assets/fonts/google/RedRose-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Red Rose';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../assets/fonts/google/RedRose-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Red Rose';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../assets/fonts/google/RedRose-Bold.ttf') format('truetype');
}

.header-title {
  font-family: $font-family-primary;
}

.strike {
  text-decoration: line-through;
}

.mw-90px {
  max-width: 90px !important;
}
